<template>
  <div class="container">
    <!-- faceCheck  人脸审核 -->
    <div class="nav">
      <div class="nav_title">人脸审批</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
      </div>
    </div>
    <div class="content">
      <van-tabs v-model="active" type="card" swipeable sticky>
        <van-tab title="待审核" style="margin-top: 20px;">
          <div class="reverse">
            <van-list
              v-if="uncheckedList && uncheckedList.length > 0"
              v-model="uncheckedLoading"
              :finished="uncheckedFinished"
              finished-text="没有更多了"
              :immediate-check="false"
              @load="onUncheckedLoading"
            >
              <div class="reverse">
                <div v-for="(item, index) in uncheckedList" :key="index" class="dish-card">
                  <div class="card">
                    <div class="card-item" style="padding-top: 0.15rem">
                      <div>申请人</div>
                      <div>{{ item.userName }}</div>
                    </div>
                    <div class="card-item">
                      <div>申请时间</div>
                      <div>{{ item.updateTime }}</div>
                    </div>
                    <div class="card-item">
                      <div>人脸照片</div>
                      <!-- <div>
                        <img :src="item.url ? `${apiUrl}/image?name=${item.url}` : none" style="width:40px;height:40px">
                      </div>-->
                      <div @click="openUncheckedList(index)">
                        <img
                          class="img"
                          :src="`${apiUrl}/image?name=${item.url}`"
                          style="width:40px;height:40px"
                        />
                      </div>
                    </div>
                    <div class="card-item" style="border-bottom: none;justify-content: flex-end">
                      <div class="btn" @click="faceCheck(item.id,2)">拒绝</div>
                      <div class="btn" @click="faceCheck(item.id,1)">同意</div>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
            <van-empty v-else description="暂无数据" style="padding-top: 40%" />
          </div>
        </van-tab>
        <van-tab title="已审核" style="margin-top: 20px;">
          <van-list
            v-if="checkedList && checkedList.length > 0"
            v-model="checkedLoading"
            :finished="checkedFinished"
            finished-text="没有更多了"
            :immediate-check="false"
            @load="onCheckedLoading"
          >
            <div class="reverse">
              <div v-for="(item, index) in checkedList" :key="index" class="dish-card">
                <div class="card">
                  <div class="card-item" style="padding-top: 0.15rem">
                    <div>状态</div>
                    <div>{{ item.status === 1 ? '同意' : item.status === 2 ? '拒绝' : '' }}</div>
                  </div>
                  <div class="card-item" style="padding-top: 0.15rem">
                    <div>申请人</div>
                    <div>{{ item.userName }}</div>
                  </div>
                  <div class="card-item">
                    <div>申请时间</div>
                    <div>{{ item.updateTime }}</div>
                  </div>
                  <div class="card-item" style="border-bottom: none">
                    <div>人脸照片</div>
                    <!-- <div>
                      <img :src="item.url ? `${apiUrl}/image?name=${item.url}` : none" style="width:40px;height:40px">
                    </div>-->
                    <div @click="openCheckedList(index)">
                      <img
                        class="img"
                        :src="`${apiUrl}/image?name=${item.url}`"
                        style="width:40px;height:40px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
          <van-empty v-else description="暂无数据" style="padding-top: 40%" />
        </van-tab>
      </van-tabs>
    </div>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import { getManagerFaceCheckList, getApproval } from "@/api/person";
import cancel from "@/assets/cancel.png";
import { Dialog, ImagePreview } from "vant";
import none from "@/assets/images/photo_none.png";
// import { getAccInfo } from '../../api/person'

export default {
  name: "FaceCheck",
  data() {
    return {
      none,
      uncheckedList: null,
      uncheckedLoading: false,
      uncheckedFinished: false,
      uncheckedPagination: {
        current: 1,
        size: 10,
        total: 0,
        status: 0,
      },
      checkedList: null,
      checkedLoading: false,
      checkedFinished: false,
      checkedPagination: {
        current: 1,
        size: 10,
        total: 0,
        status: 1,
      },
      active: 0,
      title: "",
      show: false,
      cancel,
      managerId: null,
      infoData: null,
      apiUrl: process.env.VUE_APP_BASE_API,
    };
  },
  computed: {
    date() {
      return (day) => {
        if (day) {
          return day.substr(-8);
        } else {
          return "-";
        }
      };
    },
  },
  created() {
    // this.getInfo()
    this.infoData = JSON.parse(sessionStorage.getItem("personInfo"));
    this.apiUrl = process.env.VUE_APP_BASE_API;
    // console.log('人脸审批', this.apiUrl)
    // console.log(this.infoData)
    // 判断，非空判断，以免报错：无法读取null的属性
    if (this.infoData) {
      if (this.infoData.id) {
        this.managerId = this.infoData.id;
      }
    }

    const { role } = this.$store.getters;
    this.title = role === 0 ? "主管" : "班主任";
    const { uncheckedPagination, checkedPagination } = this;
    // const { current, size } = this.uncheckedPagination
    this.unchecked(uncheckedPagination);
    this.checked(checkedPagination);
  },
  methods: {
    // async getInfo() {
    //   const res = await getAccInfo()
    //   console.log(res)
    //   if (res.code === 0) {
    //     this.$nextTick(() => {
    //       this.infoData = res.data
    //       localStorage.setItem('personInfo', JSON.stringify(this.infoData))
    //     })
    //   }
    // },
    toBack() {
      this.$router.back();
    },

    // 点击图片预览
    openUncheckedList(data) {
      ImagePreview([
        this.apiUrl + `/image?name=` + this.uncheckedList[data].url,
      ]);
    },
    openCheckedList(data) {
      ImagePreview([this.apiUrl + `/image?name=` + this.checkedList[data].url]);
    },

    faceCheck(id, status) {
      const message = status === 2 ? "确定拒绝该申请？" : "确定同意该申请？";
      Dialog.confirm({
        title: "审批",
        message,
      })
        .then(async () => {
          // ___________________________
          const { code, message } = await getApproval({ id, status });
          if (code === 0) {
            this.uncheckedList = null;
            this.checkedList = null;
            this.uncheckedPagination = {
              current: 1,
              size: 10,
              total: 0,
              status: 0,
            };
            this.checkedPagination = {
              current: 1,
              size: 10,
              total: 0,
              status: 1,
            };
            this.unchecked(this.uncheckedPagination);
            this.checked(this.checkedPagination);
          } else {
            console.log(message);
          }
          // ______________________________
        })
        .catch(() => {
          // on cancel
        });
    },
    // sleep(numberMillis) {
    //   var now = new Date()
    //   var exitTime = now.getTime() + numberMillis
    //   while (true) {
    //     now = new Date()
    //     if (now.getTime() > exitTime) return
    //   }
    // },

    // 未审核
    async unchecked() {
      // const { uncheckedList } = this;
      // (uncheckedList.length === 0) && (this.show = true)
      const { current, size, status } = this.uncheckedPagination;
      const res = await getManagerFaceCheckList({
        current: current,
        size: size,
        status: status,
        manager: this.managerId,
      });
      // console.log(res)
      // console.log(res.data.person) // +++++++
      const { code, data, message } = res;
      // const { code, data, message } = res.data.person // +++++++
      if (code === 0) {
        this.uncheckedList = data.records;
        // console.log('未审核列表', this.uncheckedList)
        this.uncheckedPagination = {
          current: data.current,
          size: data.size,
          status: 0,
          total: data.total,
        };
        setTimeout(() => {
          this.uncheckedLoading = false;
        }, 1000);
        this.show = false;
      } else {
        console.log(message);
      }
    },

    // 已审核
    async checked() {
      // const { checkedList } = this;
      // (checkedList.length === 0) && (this.show = true)
      const { current, size, status } = this.checkedPagination;
      const res = await getManagerFaceCheckList({
        current: current,
        size: size,
        status: status,
        manager: this.managerId,
      });
      console.log(res);
      const { code, data, message } = res;
      if (code === 0) {
        this.checkedList = data.records;
        this.checkedPagination = {
          current: data.current,
          size: data.size,
          status: 1,
          total: data.total,
        };
        setTimeout(() => {
          this.checkedLoading = false;
        }, 1000);
        this.show = false;
      } else {
        console.log(message);
      }
    },

    toHome() {
      this.$router.back();
    },

    onUncheckedLoading() {
      const { current, size, total } = this.checkedPagination;
      if (current * size >= total) {
        this.uncheckedFinished = true;
      } else {
        this.unchecked({ current: current + 1, size });
      }
    },

    onCheckedLoading() {
      const { current, size, total } = this.uncheckedPagination;
      if (current * size >= total) {
        this.checkedFinished = true;
      } else {
        this.checked({ current: current + 1, size });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .content {
    padding: 4rem 1rem 0 1rem;
    .add {
      background-color: #1989fa;
      color: #fff;
      display: inline-block;
      padding: 0.4rem 2rem;
      border-radius: 3rem;
      margin-bottom: 1rem;
    }
    .reverse {
      .dish-card {
        padding-bottom: 1.5rem;
      }
    }
    .title {
      display: flex;
      align-items: center;
      //padding: 0.5rem 1.5rem .4rem;
      padding-bottom: 0.5rem;
      text-align: center;
      & > .section {
        display: flex;
        justify-content: flex-end;
        span {
          padding: 0.1rem;
          border: 1px solid #1989fa;
          color: #1989fa;
          border-radius: 50%;
          font-size: 0.875rem;
          background-color: #fff;
          margin-left: 2rem;
          width: 1.3rem;
          height: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      div {
        width: 33.3%;
        font-weight: 600;
      }
    }
    .card {
      background-color: #fff;
      border-radius: 0.625rem;
      padding: 0.625rem 1rem;
      box-shadow: 0 0 0.875rem #dcdcdc;
      .cancel-end {
        display: flex;
        justify-content: flex-end !important;
        padding-top: 0 !important;
      }
      & > .dishes {
        border-bottom: 1px dashed #ccc;
        .dish {
          padding: 0.5rem 0;
          justify-content: space-between;
          .common-center {
            display: flex;
            align-items: center;
          }
        }
        //display: flex;
      }
      & > .card-item {
        border-bottom: 1px dashed #ccc;
        padding: 0.5rem 0;
        //color: #07c160;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn {
          padding: 0.3rem 1rem;
          border-radius: 0.3rem;
          color: #fff;
        }
        .btn:first-child {
          background-color: #f56c6c;
        }
        .btn:nth-child(2) {
          background-color: #409eff;
          margin-left: 10px;
        }
      }
      .footer {
        padding-top: 0.5rem;
        //border-top: 1px dashed #ccc;
        ::v-deep .cancel {
          font-size: 0.875rem !important;
        }
        .btn {
          background-color: #ff9460;
          color: #fff;
          padding: 0.3rem 1rem;
          border-radius: 0.3rem;
          font-size: 0.875rem;
          font-weight: normal;
          display: inline-block;
        }
      }
    }
    .show-qs {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
.common-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::v-deep .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}
::v-deep .van-tabs__nav--card {
  border: 1px solid #607483;
}
::v-deep .van-tab.van-tab--active {
  background-color: #607483 !important;
}
::v-deep .van-tab {
  color: #607483;
  border-right: 1px solid #607483;
}
</style>
